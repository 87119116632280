import { noop } from 'lodash-es'
import { useSwiperSlide } from 'swiper/react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import Link from 'next/link'
import { Text } from '@libs-components/components/text'
import { Icon } from '@libs-components/components/icon'
import ShowMoreButton from '@/components/elements/show-more-block/show-more-button'
import { shopPaths as SHOP_PATH } from '@/libs/routes'

type Props = {
  feedback: Feedbacks.Data
  onClick: () => void
}

const UserFeedback = ({ feedback, onClick }: Props) => {
  const { t } = useTranslation('feature-backend-controlled-component')
  const { isActive } = useSwiperSlide()

  return (
    <div
      className={twMerge(
        'relative flex w-full cursor-pointer items-center justify-center border-none bg-transparent px-0 pb-4 pt-[20px] transition-opacity',
        isActive ? 'opacity-100' : 'opacity-30',
      )}
      onClick={onClick}
    >
      <div className='shadow-custom mobileUp:max-h-[154px] mobileUp:min-h-[154px] mobileUp:w-[683px] mobileUp:px-[30px] relative flex max-h-[179px] min-h-[179px] w-full max-w-[683px] flex-col rounded-2xl px-5 pb-5 pt-6'>
        <div className='bg-grayscale-000 text-grayscale-800 shadow-custom mobileUp:left-[30px] absolute left-[16px] top-[-15px] z-10 flex h-[32px] items-center justify-center gap-1 rounded-full px-[14px]'>
          <Icon size={18} type='emoji_people' />
          <Text variant='label_s_500'>
            {feedback.attributes.userName} {t('user_feedback_section.says')}:
          </Text>
        </div>

        <Text variant='body_m_400' className='clamp-line-5 relative text-left leading-[1.75]'>
          {feedback.attributes.content}

          <ShowMoreButton isShowMore={false} onClick={noop} />
        </Text>

        <div className='mt-2 flex items-center gap-2'>
          <Link
            onClick={e => {
              e.stopPropagation()
            }}
            href={SHOP_PATH.DETAIL({ productId: feedback.id })}
            className='flex items-start gap-1 no-underline'
          >
            <div className='from-primary-400 bg-gradient-to-b to-purple-300 bg-clip-text text-transparent'>
              <Icon type='military_tech' />
            </div>
            <Text
              variant='label_s_400'
              className='from-primary-400 clamp-line-2 mobileUp:clamp-line-1 bg-gradient-to-b to-purple-300 bg-clip-text text-transparent no-underline decoration-purple-300 underline-offset-2 hover:underline'
            >
              {feedback.attributes.name}
            </Text>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default UserFeedback
