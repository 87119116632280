import Link from 'next/link'
import { useMemo } from 'react'
import { WuModal } from '@libs-components/components/modal'
import { Text } from '@libs-components/components/text'

import { iso8601StrToFormat } from '@/libs/utils/time'
import { shopPaths as SHOP_PATH } from '@/libs/routes'

import WuImage from '@/components/Image'
import UserInfoBar from '@/pages/product/components/reviews/user-info-bar'
import { randomUserImage } from '@/pages/product/components/reviews/constants'

type Props = {
  onClose: () => void
  activeFeedback: Feedbacks.Data
}

const FeedbackModal = ({ onClose, activeFeedback }: Props) => {
  const materialId = activeFeedback.id
  const { userName, createdAt, rating, content } = activeFeedback.attributes || {}
  const randomUserImgUrl = useMemo(
    () => randomUserImage[Math.floor(Math.random() * randomUserImage.length)],
    [],
  )

  return (
    <WuModal open padding='1.25rem 1rem 1rem 1rem' maxWidth='500px' onClose={onClose}>
      <div className='flex max-h-[475px] flex-col gap-2 overflow-y-scroll'>
        <UserInfoBar
          userName={userName}
          imgUrl={randomUserImgUrl}
          createdAt={createdAt ? iso8601StrToFormat(createdAt) : ''}
          rating={rating ?? 0}
        />
        <Text variant='body_m_400' className='whitespace-pre-wrap'>
          {content}
        </Text>
        {activeFeedback.product && (
          <div className='bg-background-light flex rounded-lg p-2'>
            <WuImage
              src={activeFeedback.product.attributes.shopCoverImageUrl}
              alt='feedback modal material'
              width={75}
              height={42}
              className='mr-2 max-w-full rounded-[4px] object-cover'
            />
            <Link
              href={SHOP_PATH.DETAIL({ productId: materialId })}
              className='decoration-grayscale-500 leading-[0px] underline underline-offset-2'
            >
              <Text variant='label_s_400' color='grayscale-500'>
                {activeFeedback.product.attributes.name}
              </Text>
            </Link>
          </div>
        )}
      </div>
    </WuModal>
  )
}

export default FeedbackModal
